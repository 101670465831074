import Navigation from "./../components/Navigation";
import styled from "styled-components";
import { Main, Header, Img, Rubrik1 } from "../styles/AboutStyles";

import flygbild from "./../images/flygbild2.jpg";
import rubrik1 from "./../images/rubrik_visit.png";
import HomeButton from "../components/HomeButton";

const Iframe = styled.iframe`
    width: 100%;
    height: 400px;
    border: 1px solid black;
    margin-bottom: 1rem;
`;

const History = () => { 
    return (
        <>
            <Navigation page={'visit'} />
            <Main>
                <article>            
                    <Header><h1 alt={"Kom till Oxelö Krog!"}><Rubrik1 src={rubrik1} width='100%' style={{margin: '0 auto', maxWidth: '700px'}}  /></h1> </Header>
                    <div>
                        <Img src={flygbild} />
                        <p>
                            Gamla Oxelösund är en skyddad kulturbygd med en gammal bevarad skärgårdsmiljö. 
                            Den gamla sjökrogen är Oxelösunds äldsta byggnad. 
                            Hit kommer besökare inifrån landet, från skärgården med båt och friluftsmänniskor för att äta, fika och njuta av utsikten. 
                        </p>
                        <p>
                            Till Gamla Oxelösund kan man komma både landvägen och sjövägen. Du kan åka bil eller promenera hit på 20 minuter från Oxelösund centrum, där även busshållplats finns.
                        </p>
                        <p>
                            Från centrum och hit kan man även gratis ta Lionståget som går två gånger per dag, alla dagar utom söndag.<br />
                            Avgång från centrum 12:30 och 15:30.<br />
                            Avgång från Gamla Oxelösund kl 13 och 16.
                        </p>
                        <p>
                            Du kan även åka turisttaxi hit för endast 40 kr, om du åker från någon av de utvalda hållplatserna.
                            Mer info <a target="_blank" href="https://www.visitoxelosund.se/se-och-gora/turisttaxi">här</a>.
                        </p>
                        <p>
                            Slutligen går det ju också fint att anlända med båt! Vid vår fina gästbrygga kan man lägga till gratis i tre timmar!
                        </p>
                        <p>
                            Väl här kan du uppleva mer än den underbara utsikten från krogbacken. 
                            Här finns den vackra Kulturstigen som du kan promenera med information om alla gamla kåkar i området. 
                            Här finns Skärgårdsmuséet, Sjöfartsmuséet, Järnvägsmuséet och Mästerlotsen. Av Hembygdsföreningen kan du hyra en roddbåt och bege dig ut på sjön. 
                        </p>
                        <p>
                            Och så har vi alla våra konserter!
                        </p>
                        <p>
                            Varmt välkomna!
                        </p>

                        <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1037.5163649980389!2d17.1278253!3d58.6622473!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4658cc82e7136377%3A0x9e50a2dd2271f318!2sOxel%C3%B6%20Krog!5e0!3m2!1ssv!2sse!4v1646396010134!5m2!1ssv!2sse"></Iframe>
                        
                        <HomeButton />                        
                    </div>

                </article>      
            </Main>
        </>
    );
}

export default History;