import styled from "styled-components";

import Epost from ".././components/Epost";

import Blandat from "./../images/artister/2024/blandat.jpg";

const ExtraImage = styled.img`
  width: 180px;
  float: left;
  margin: 2px 1rem 0.3rem 0;
`;


const artistInfo = [

  {
    id: 2,
    namn: function () {
      return <>MIDSOMMARAFTON</>;
    },
    info: function () {
      return (
        <>
          <p>
            Traditionsenligt så firar vi midsommar på Oxelö Krog med dans och lekar kring midsommarstången till levande musik!<br />

            Midsommarstången kommer att kläs och resas mellan kl 10 - 12.<br />
            Kl. 13 blir det musik och ringlekar med Lotta och Lena!<br />

            <p>Caféet är öppet 10 till 16. Denna dag har vi en speciell midsommarmeny som ser ut så här:
            </p>
            <ul>
              <li>Midsommartallrik 195 kr - 3 sorters sill, varmrökt lax, räkröra, gräddfil, gräslök, kokt ägg, potatis, smör och bröd.</li>
              <li> Varmrökt lax med fransk potatissallad och romröra samt smör och bröd</li>
              <li>Räksmörgås</li>
              <li> Pannkakor med grädde och sylt</li>
            </ul>
            <p>Dessutom har vi förstås fika med bland annat vår fina midsommartårta med jordgubbar</p>
            Varmt välkomna!<br /><br />
            Glöm inte att ta med folkdräkt om du har en!
          </p>
          <p>OBS! På midsommarafton kommer det inte vara möjligt att parkera på Kroggränd eftersom vi kommer ha dansen där. Vi hänvisar istället till stora parkeringen.</p>
        </>
      );
    },
    datum: "2024-06-21",
    img: "midsommar",
    pris: 0,
    tid: "",
    kväll: false,
    music: true,
  },

  {
    id: 3,
    namn: function () {
      return <>Herman Lindqvist</>;
    },
    info: function () {
      return (
        <>
          <p>
            Vi är oerhört glada att det har blivit en tradition att Herman Lindqvist kommer till oss! Vi hälsar honom hjärtligt välkommen tillbaka till Krogbacken!
            Den här gången kommer Herman att berätta på temat "Ska Sverige alltid vara en monarki?" utifrån några av de böcker han skrivit; bl.a. "Bernadotter- för Sverige hela tiden".
          </p>
          <p>Ingen kan väl som Herman berätta vittert och underhållande direkt från minnet! Missa inte det!</p>
          <p>
            Varmt välkomna!
          </p>
        </>
      );
    },
    datum: "2024-06-25",
    img: "Herman-Lindqvist",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
  },

  {
    id: 4,
    namn: function () {
      return <>Bengan Janson</>;
    },
    info: function () {
      return (
        <>
          <p>
            Att placera in den glade spelmannen Bengan Janson i ett fack låter sig inte göras. Med hänsynslös lust ger han sig in i jazzens, visans, folkmusikens och musik-komikens värld, allt är spelbart. Genom en nära publikkontakt och en osedvanlig musikalisk begåvning hanterar han både tangenter och publik på ett märkvärdigt lättsamt och begåvat sätt. ”Möten med andra musikanter är min drivklart” säger Bengan och det kan verkligen märkas i hans meritlista.
          </p>
          Tangentekvilibristen och spelmannen Bengt-Åke började lira dragspel som åttaåring. När Bengan var 10 spelade han med basisten Sture Nordin och den legendariske Count Basie-trummisen Sonny Paige. En tidig influens var också jazzpianisten Gästa Svensson med vilken han och brorsan Tore spelade dansmusik runt om på dansbanorna i Hälsingland. Ett möte med riksspelmannen Björn Ståbi på körstämman 1989 gjorde en start intryck på vår bälghanterande hälsingeyngling. Detta resulterade i ett långt, från båda håll respektfullt samarbete, med år av spelmangig och spelmansstämmor. Folkmusikgenen var integrerad och bidrog säkerligen till att Bengan tillsammans med Per Gudmundson 2019 kunde knipa en Grammis i kategorin Folkmusik.
          <p>
            Under 90-talet gjordes otaliga gig och ca 100 timmar liveTV med musik komikbandet Östen med Resten. Revy med Thomas von Brömsen och Ulla Skoog, många års samarbete och internationellt turnerande med sopranen Anne-Sofie von Otter, många års turnerande med Kalle Moreaus och turné och platta med Kristina Lugn och Allan Edwall. Han har samarbetat, med Putte Wikman, Dogge Doggelito, Marie Fredriksson, Jan Lundgren, Totta Näslund, Lisa Nilsson, Janne Åström, Jill Jonsson, Lill Lindfors, Charlotte Pirelli, Thomas Di Leva, Sven Bertil Taube, Claes Janson, Loa Falkman, Tina Ahlin och många, många fler.
          </p>
          <p>
            Till krogbacken kommer Bengan tillsammans med den fantastiska gitarrvirtuosen Emil Ernebro och en av Sveriges bästa jazzbasister, Hans Backenroth. En otrolig multimusikalisk kväll att vänta!
          </p>
        </>
      );
    },
    datum: "2024-06-29",
    img: "bengan",
    pris: 230,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "",
    slutsålt: false,
    biljett_id: "89zg819apbewkuz",
  },

  {
    id: 5,
    namn: function () {
      return <>Monica Dominique och<br />Tina Ahlin Quartet</>;
    },
    info: function () {
      return (
        <>
          <p>
            Monica Dominique och Tina Ahlin Quartet tar sig många musikaliska friheter och bjuder på ett program med stor spännvidd!
            Med sina fingrar, röster, munspel och dragspel så klingar deras arrangemang djupt rotade i den svenska traditionen; Jazz från Monica Z till Duke Ellington, visor med skarpa, geniala texter av Olle Adolphsson, Beppe och Tage Danielsson - varvat med Piazolla, Solar Plexus och Debussy!
            Med sig på scenen har de den fenomenala världstrumslagaren Morgan Ågren och kontrabasisten från Norrköpings Symfoniorkester Johan Strindberg.
          </p>
          <p>
            Monica Dominique behöver knappast något presentatiton. Hon är en uppskattad jazzpianist, kompositör, arrangör och kapellmästare, utbildad på
            Musikhögskolan i Stockholm. Tillsammans med maken Carl-Axel Dominique ger hon ofta konserter för fyrhändigt piano med en blandning av klassiskt och improviserat. Hon var medgrundare till allmusikgruppen Solar Plexus 1969, där hennes sväng på hammondorgel blev ett av gruppens främsta kännetecken. Hon ingick också en period i sånggruppen Gals and Pals och har även samarbetat med bl.a. Monica Zetterlund, Carla Bley, Hasse & Tage och Povel Ramel. Hon har komponerat film- och teatermusik, körverk, storbandssviter och en mängd sånger. En av hennes mest framförda är "Tillägnan" som komponerades till hennes trio-LP från 1980 med brodern Palle Danielsson på bas och Leroy Lowe på trummor. Monica invaldes 2016 i Swedish Music Hall of Fame.
          </p>
          <p>
            Tina Ahlin, pianist, sångerska, munspelare, kompositör sedan 30 år tillbaka utbildades på Stockholms Musikgymnasium och Musikhögskolan i Stockholm. Genom åren har Tina samarbetat med en rad kända namn, såsom Hasse Alfredson, Robert Broberg, Lorry-gänget, Olle Adolphsson för att nämna några.  Hon samarbetar årligen även med körer, orkestrar och storband och har arrangerat och tonsatt många körverk. Senaste "Toner och tankar från roten", baserad på Tage Danielssons lyrik. Tina har varit programledare i en serie uppskattade musikmöten i SVT:s Gokväll och likaså varit högtidsvärd för SVT:s Valborg, Nationaldag och Midsommar. Tina har släppt tre soloskivor – varav två, “Sommarkort” och “12 sånger av Allan Edwall” blev grammis-nominerade. Hon har gett ut två böcker; ”Svenska barnvisor” och ”Om sommaren sköna”. 2013 startade samarbetet med Orsa Spelmän och som pågår än. Tina fick 2021 Tage Danielssons Vänners stipendium.
          </p>
        </>
      );
    },
    datum: "2024-07-06",
    img: "Tina_Monica",
    pris: 230,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "Nils Gyllbäck",
    slutsålt: false,
    biljett_id: "GGD2LTNENCZLNWW",
  },

  {
    id: 6,
    namn: function () {
      return <>Amer Sarsour</>;
    },
    info: function () {
      return (
        <>
          <p>
            Vi har den stora glädjen att hälsa Amer Sarsour välkommen till krogbacken! I januari kom hans debutroman "Medan vi brinner" på Norstedts förlag och den har fått mycket uppmärksamhet och fina recensioner i pressen. Amer Sarsour har framträtt på den nationella och internationella poesiscenen under de senaste tio åren. Han har gått på Biskop Arnös författarskola och har en master i retorik från Uppsala universitet. För sitt arbete som workshopledare i kreativt skrivande tilldelades Amer Sarsour Uppsala kommuns stipendium till Gösta Knutssons minne.
          </p>
          <p>
            Bokens handlar om den tjugotreåriga Omar som har tagit på sig ett viktigt uppdrag för att hjälpa sin pappa. Han ska åka ner till södra Italien och hämta sina kusiner. Året är 2013, de har flytt det brinnande Syrien, korsat havet och tagit sig till Europa. Nu ska Omar hjälpa dem den sista biten på vägen, hem till tryggheten i Sverige.
          </p>
          <p>
            Från början är Omar taggad, det här är ett perfekt sätt att visa för pappan att han är vuxen och kan hantera situationen som en man. Men resan visar sig vara svårare än han trott. Inte bara på grund av de väktare, poliser och passkontrollanter som verkar lura bakom varje hörn. Kusinerna är inte riktigt som han minns dem från barndomen, och de verkar ha svårt att lita på honom. Dessutom börjar han tvivla på sig själv. Är han verkligen rätt man för uppdraget?
          </p>
          <p>
            Medan vi brinner är en driven debutroman. På glödande prosa, med både humor och patos, skriver Amer Sarsour om migration i de stängda gränsernas tid. Det är också en berättelse om vänskap och brödraskap. Om att bli vuxen genom att få syn på sig själv.
          </p>
          <p>
            Dagen efter sitt besök på krogbacken kommer Amer att hålla en workshop på Oxelösunds bibliotek.
          </p>
        </>
      );
    },
    datum: "2024-07-02",
    img: "amer-sarsour",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
  },

  {
    id: 7,
    namn: function () {
      return <>Håkan Juholt</>;
    },
    info: function () {
      return (
        <>
          <p>
            Håkan Juholt, som sedan 2020 är ambassadör i Sydafrika, är i Sverige några sommarveckor och då förärar han oss med ett besök på Oxelö Krog! Det är vi väldigt glada över!
            Tillsammans med Ted Karlberg har Håkan skrivit boken Silldiplomati. Ett solitt bokverk om hur sill kan rädda en regnig midsommarfest men också överbrygga diplomatiska klyftor.
          </p>
          <p>
            Silldiplomati handlar bland mycket annat om berikande och osannolika möten, vilka samtliga haft sillen som huvudrollsinnehavare.
            Håkan har gärna och ofta sillen till bords, berättar han. Det har han haft nu i ganska många år. Han praktiserar skickligt, som Sveriges ambassadör i Sydafrika, inte bara vanlig svensk diplomati. Enligt egen utsago praktiserar han framgångsrikt även "Silldiplomati" i officiella sammanhang. Aha, sillen alltså en "icebraker" och inte bara en matbit. Intressant, smaka på den! Silldiplomati ger dig många dimensioner - reseskildringar, nya recept, sillens historia, personporträtt, glada snappsvisor och soliga möten.
          </p>
          <p>
            "Det finns människor och så finns det människor. Jag gör inte skillnad på någon, som jag gör mellan sill och strömming, men vissa människor omges av ett ljus som är väldigt positivt och laddat. Med laddat menar jag att dessa människor inte dränerar inte dig, utan du uppfylls av glädje och energi efter ett möte med dem. Jag har blivit välsignad med att känna en hel drös med just sådana människor och vill nu påstå att du som sitter med denna bok i ditt knä har en otrolig tur, för du skall få träffa några av dem i denna berättelse kring snapsen."
          </p>
          <p>
            Det är med stor glädje vi hälsar Håkan Juholt välkommen till Oxelö Krog!
          </p>
        </>
      );
    },
    datum: "2024-07-16",
    img: "juholt",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
  },

  {
    id: 8,
    namn: function () {
      return <>Eric Bibb</>;
    },
    info: function () {
      return (
        <>
          <p>
            Eric Bibb, född och uppvuxen i New York, är med sina över fyrtio album och mer än femtio år på scenen, en av den internationella bluesvärldens största namn idag och hyllningarna som en av världens främsta akustiska bluesgitarrister är ständigt återkommande.
          </p>
          <p>
            Han har turnerat världen runt i mer än tjugofem år, blivit Grammy-nominerad i USA tre gånger och fått mängder av andra internationella utmärkelser, som t. ex pris för årets akustiska bluesalbum från The Blues Foundation i Memphis 2017 & 2022 och ”Blues Act Of The Year” vid Jazz FM Awards i London 2019. 2022 fick Eric även Stockholms Stads hederspris i musik.
          </p>
          Erics far, artisten Leon Bibb, kämpade jämsides med Martin Luther King, Harry Belafonte, Joan Baez och många fler för svartas rättigheter i USA på sextiotalet. Leons framtidsvisioner och mål-medvetenhet tog sig uttryck i musiken och formade Erics konstnärsskap från tidig ålder. Hemma hos familjen Bibb rörde sig en konstant ström av erkända musiker och artister som John Lewis (Er-ics morbror - pianist/kompositör, grundare av Modern Jazz Quartet), Paul Robeson, Pete Seeger och Josh White. Eric minns särskilt tydligt hur han vid elva års ålder står i pyjamas i vardagsrummet och berättar för Bob Dylan att han minsann också spelar gitarr…
          <p>
            Eric har samarbetat med storheter som t.ex Mavis & Pops Staples, Taj Mahal, Odetta, Bonnie Raitt, The Blind Boys of Alabama och The Dixie Hummingbirds. 2019 spelade han för utsålda hus i en vecka på legendariska Ronnie Scott’s i London, då även Van Morrison var Eric’s gäst en av kväl-larna.
          </p>
          2017 turnerade han Sverige runt med den hyllade enmans-föreställningen ”Tales From A Blues Brother”, som också sändes i Sveriges Television. Den producerades av hans son Rennie Mirro och Filip Adamo och kan nu ses på Qwest TV. Ni har också nyligen kunnat se honom i På Spåret, SVT och Bingolotto, TV4, där han sjöng en duett med Sarah Dawn Finer. Sommaren 2022 såg ni honom i SVT:s Allsång På Skansen!
          <p>
            2021 släppte han plattan ”Dear America” med gäster som bl. a Ron Carter, Eric Gales och Steve Jordan. 2023 släpptes hans opus Ridin’, den liksom Dear America producerades av Glen Scott. Nu med gäster som Taj Mahal och Russell Malone.
          </p>
          I september 2022 kunde du se honom på Scalateaterns scen Stockholm tillsammans med ett spännande band och gästerna Sarah Dawn Finer och Rennie Mirro. Konserten spelades in och filmades inför ett live-album, som släpps i april 2024.
          <p>
            I höstas var han även aktuell i föreställningen Ögonblicket på Västmanlands Teater.
          </p>
          <p>
            Vi är otroligt glada över att Eric vill komma tillbaka till Krogbacken! Den här gången kommer han att ha med sig den fantastiske Greger Andersson på munspel & sång (även kallad Knock-Out Greg) samt Ulrika Bibb.
          </p>
        </>
      );
    },
    datum: "2024-07-13",
    img: "eric_bibb",
    pris: 250,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "Jan Malmström",
    slutsålt: true,
    biljett_id: "UWEWVUVZCHW9W29",
    youtube: "https://youtu.be/HYhwUgl3P0U?si=Rcf3quZItAXlTj_i",
    youtubenamn: "Family",
    youtube2: "https://youtu.be/j__moFFnAJg?si=ek6RKqQSL1YU0HyU",
    youtubenamn2: "500 miles",
  },

  {
    id: 9,
    namn: function () {
      return <>Wille Crafoord</>;
    },
    info: function () {
      return (
        <>
          <p>
            Text- och melodivirtuosen Wille Crafoord ger en unik konsert på Krogbacken! Wille kommer med en låtskatt från såväl egna hyllade plattor, JustD och nu senast historieprojektet Tbaxtstan, tillsammans med cellovirtuosen Jonas Bleckman. En oförglömlig kväll utlovas!
          </p>
          <p>
            Wille Crafoord gjorde sig först känd som en av medlemmarna i rapbandet Just D i slutet av åttiotalet, med hits som ”Grannar”, ”Tre gringos” och ”Vart tog den söta lilla flickan vägen?”. Han tog emot Rockbjörnen 1996 och har genom åren tilldelats flera Grammisar.
          </p>
          <p>
            Efter JustD har Crafoord givit ut soloalbum som Samma typ av annorlunda saker, Den dära skivan, Om det så går under går det över och Live at Riddarhuset, där han bland annat kompades av Salem Al Fakir. Musikaliskt rör han sig i en blandning av jazz, soul och visa. Alltid i en ansats att föra den svenska melodi- och texttraditionen vidare. Han har också samarbetat med Bo Kaspers orkester, Nanne Grönvall och Magnus Lindberg, samt skrivit och framträtt i hyllade föreställningar som Den Dära Showen 1999, Django & Jag 2008 (om gitarrlegenden Django Reinhardt) och Snurra min jord på Vasateatern 2008-2009 med texter av Lars Forssell. Wille Crafoord belönades med Ulf Peder Olrog-priset 2012.
          </p>
          <p>Numera är Wille Crafoord en mycket omtyckt trubadur och kompositör med flera soloalbum bakom sig. 1997 fick han det av Povel Ramel instiftade Karamelodiktstipendiet, med motiveringen: ”För lekfull ordbehandling – oftast mitt i prick och för musik med ny typ av annorlunda kick.” Wille Crafoord är född 1966 och bor i Stockholm.
          </p>
        </>
      );
    },
    datum: "2024-07-20",
    img: "crafoord",
    pris: 200,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "",
    slutsålt: false,
    biljett_id: "W0T5A938U5081W3",
    youtube: "https://www.youtube.com/watch?v=aklYyRlv8tE",
    youtubenamn: "Påfågelfjäder",
    youtube2: "https://www.youtube.com/watch?v=fzgrCyhTC3k",
    youtubenamn2: "Typiskt dej",
  },

  {
    id: 10,
    namn: function () {
      return <>Allsång på Krogbacken</>;
    },
    info: function () {
      return (
        <>
          <p>
            En skön allsångskväll på Krogbacken! Visgruppen Gott och Blandat bjuder dig att sjunga med i en mängd svenska visor av bl.a: Evert Taube, Nils Ferlin, Cornelis, Fred Åkerström och Ted Gärdestad men även en och annan schlager eller poplåt! Det är härligt att sjunga! Och vad kan vara mysigare än att göra det tillsammans på krogbacken med utsikt över skärgårdsviken! Ta med din bästa sångröst och sjung med!
          </p>
          <p>
            <ExtraImage src={Blandat} />
            Gott och Blandat bildades hösten 2015 och är delvis en fortsättning av Sånggruppen "Blandat" som var mycket populära från mitten 70 talet fram till 2005. Gruppen medverkade i TV program som Cafe Norrköping med Ragnar Dahlberg och Dax med Åke Wilhelmsson.
          </p>
          <p>
            Blandat gjorde två LP-skivor som spelades flitigt i både riks och lokalradion och var även på test till Svensktoppen. Gruppen uppträdde bl.a på Skansen Solidens stora scen inför sextusen entusiastiska åhörare med konferencier Bosse Larsson och ett artistuppbåd och placerade sig på en hedrande andraplats i en riksomfattande Taubetävling som anordnades av landets lokalradiostationer.
          </p>
          <p>
            Medlemmar:<br />
            Tomas Björklund: Gitarr & sång.<br />
            Kenneth Karlsson: Bas & percussion<br />
            Lasse Fornarve: Klaviatur, dragspel & sång<br />
          </p>
        </>
      );
    },
    datum: "2024-07-24",
    img: "allsång",
    pris: 100,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "",
    slutsålt: false,
    biljett_id: "epbjvd52vzajdba",
  },

  {
    id: 11,
    namn: function () {
      return <>BELLMANDAGEN</>;
    },
    info: function () {
      return (
        <>
          <p>
            Vår traditionella Bellmandag blir i år på en söndag. Som vanligt
            kommer krogbacken att befolkas av 1700-talsklädda individer och vi
            klär även upp vår personal!
          </p>
          <p>
            Thord Lindé kommer att underhålla såsom Fredman kl 12 och kl 14. Denna gång med tillsammans med kontrabasisten Johan Strindberg, altviolinisten Barbro Westergren och klarinettisten Linda Sohlberg.
          </p>
          <p>
            Bellman-quiz för stora och små utlovas med fina Bellman-priser!
          </p>
          <p>Varmt välkomna!</p>
        </>
      );
    },
    datum: "2024-07-28",
    img: "bellmandagen",
    pris: 0,
    tid: "HELA DAGEN",
    kväll: false,
    music: true,
    youtube: "https://www.youtube.com/watch?v=t1ggVXHyvNw",
    youtubenamn: "SOLEN GLIMMAR",

  },

  {
    id: 12,
    namn: function () {
      return <>The Blue Benders</>;
    },
    info: function () {
      return (
        <>
          <p>
            På drygt två år har The Blue Benders etablerat sig som en kraft att räkna med inom svensk blues/roots/soul/funk/rock. Inte bara som det nya unga blueshoppet, utan som en fulländad musikmaskin med ett sound och sväng som imponerar på det mest erfarna rock- och bluesrävarna. Ett band som dock inte vill låta dig definieras som enbart blues utan hämtar sina influenser från alla håll för att bilda en musikalisk cocktail från alla världar, där tunga riff möts av funkiga basgångar och hela tiden inspirerat av den afroamerikanska musiken
          </p>
          <p>
            Fronttrion, tillsammans med det tunga, ångvältande kompet är häpnadsväckande, eller ”onödigt bra”, som Roffe Wikström uttryckte det efter att de delat kväll med honom i Göteborg i december-21.
          </p>
          <p>
            Bandet, som tilldelades utmärkelsen "Årets Nykomling" i Bluespoddens omröstning 2022, har inte bara imponerat som kollektiv, utan även individuellt. Edvin Öström har två år i rad utsetts till "Årets Gitarrist", och sångaren och entertainergeniet Samson Mirro för den legendariska familjetraditionen vidare från farfar Eric Bibb och pappa Rennie Mirro. Morris Malek sanslösa slidegitarr spottar, frustar och fräser. Johan Sund (Lisa Lystam Family Band, the Bland, m fl), basisten och världsvirtuosen, är sedan hösten -22 ny basist i bandet. Trummisen och multigeniet Oskar Boså langar dynamik, sväng och stadga med sällsynt pondus, bidrar till bandets karakteristiska sound.
          </p>
          <p>
            I mitten av juli 2023 gick bandet in i Immersive music studios (gamla EMI studion) för att spela in nytt material. Resultatet blev EP:n "Strike One", som släpptes den 16e november 2023. Den efterlängtade EP:n bjuder på en ny dimension av bandets musikaliska talang och har fått enorm positiv respons från deras dedikerade fanbase. Med en utsåld releasekonsert på Nalen Klubb, 300 pers, i bagaget är bandet minst sagt inne i en spännande period och ser de fram emot att fortsätta sin resa under 2024.
          </p>
          <p>
            De gör det så bra att de tog hem vinsten i den svenska tävlingen av European Blues Challenge och drar till Portugal i april 2024 för att representera Sverige i bluesen egen Eurovision.
          </p>
          <p>
            Blue Benders live är en hyllad upplevelse och ett unikt samspel med publiken. Det är kvällar som aldrig vill ta slut.  Energi och spelglädje eller som trumlegendaren Ingemar Dunker (RIP) uttryckte det efter att han upplevt bandet live på Bangens Jazzfestival 2023, ”Det bästa jag hört på mycket mycket länge”
          </p>
          <p>
            Bandet har sitt ursprung ur Stockholms jam-scen i Gamla Stan. Edvin drog med sig Morris till jammen redan under första året på musikgymnasiet, Rytmus. Stampen, St Clara Wirströms, Engelen, etc blev deras musikaliska vattenhål under gymnasieåren. Samson kom snart in i bilden och det jammades i parti och minut. Tommy Moberg, den gamle legendaren, såg potentialen och satte ihop ett band omkring grabbarna, som hade sin första officiella spelning under namnet The Blue Benders i maj 2021 i samband med Old Town Blues Stream.
          </p>
        </>
      );
    },
    datum: "2024-08-03",
    img: "bluesbenders",
    pris: 200,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "",
    slutsålt: false,
    biljett_id: "8YVYD52LGXA77ND",
  },

  {
    id: 13,
    namn: function () {
      return <>Niklas Natt och Dag</>;
    },
    info: function () {
      return (
        <>
          <p>
            Det är med stor stolthet vi hälsar Niklas Natt och Dag välkommen tillbaka till Krogbacken i sommar!
          </p>
          <p>
            Efter de stora framgångarna med 1793, 1794 och 1795 – den prisbelönta och hyllade trilogin Bellman noir kom Niklas Natt och Dag i höstas med första delen i en ny romansvit som handlar om hans egen släkt. I Ödet och hoppet löser han den svenska medeltidens mest ökända mord – mordet på Engelbrekt. Denna mäktiga, historiska roman tar sin början i juni 1434. Följ med på en lika fascinerande som allmänbildande läsresa!
            "Niklas Natt och Dag gör medeltiden ruggigt spännande" skrev Lotta Olsson i DN.
          </p>
          <p>
            År 1434: Kalmarunionen knakar i fogarna. Från Danmark styr Erik av Pommern med osäker hand. Ett uppror bryter ut i norr, lett av bergsmannen Engelbrekt Engelbrektsson. Till hans sida skickas den unge Måns Bengtsson att vinna Engelbrekts gunst för att främja den ätt som bär sköld i guld och blått. På Göksholm vid Hjälmarens strand lämnar han mor, far och syster. En förlorar sig i saknad, en smider ränker om tron och krona, en ges möjlighet att resa sig ur sin broders skugga.
          </p>
          <p>
            Niklas Natt och Dag är född och uppvuxen i Stockholm och ättling till en av de äldsta ätterna i Sverige. Namnet Natt och Dag kommer från ättens vapenbild: en sköld delad i guld och blått. Under 2018 vann han Crimetime Specsavers Award för bästa deckardebut. 1793 tilldelades Svenska Deckarakademins pris för årets bästa svenska debut. År 2020 tilldelades Niklas Natt och Dag ett hederspris av Stockholms stad för att han haft "stor betydelse för kulturlivet i Stockholm". Niklas Natt och Dag är Måns Bengtssons sonson i femtonde led.
          </p>
        </>
      );
    },
    datum: "2024-07-23",
    img: "natt_och_dag",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
  },

  {
    id: 14,
    namn: function () {
      return <>Henry Bronett</>;
    },
    info: function () {
      return (
        <>
          <p>
            Vi är glada att kunna hälsa Henry Bronett välkommen till krogbacken!
          </p>
          <p>
            Henry Bronett, uppvuxen på cirkus och utbildad vid Scenskolan i Stockholm har verkat som clown, skådespelare, producent och regissör inom cirkus, teater, opera, film och tv. Sedan 2015 arbetar han huvudsakligen som författare och konstnär. 2020 förärades Henry Bronett Samfundet De Nios julpris.
          </p>
          <p>
            Henry Bronett debuterade som författare med kokboken Största möjliga njutning! 2005. År 2008 utgavs diktsamlingen Närmare nu och 2010 debuterade han som barn- och ungdomsboksförfattare med Gnuffarna på Bamseklo, vilken följdes av Charles Grandpiers Äventyr: Drakblodsfröna 2011,illustrerade av Andrea Femerstrand. 2014 kom diktsamlingen En blå Bok med illustrationer av Maria Gustafsson. En ny barnbok, Olika som Bär, 2014 med fotografier av Jeanette Håkansson. Kaddish, historietter utkom 2015. Diktsviten Reflektioner om efter Kärlek utkom 2016. Han har även varit kåsör och debattör i de större dagstidningarna. Sedan 2015 ställer han ut fotografiska verk och texter inom genren: ”fotopoesi”.
          </p>
          <p>Vad han kommer att berätta om på krogbacken återstår att se. Men det finns ju mycket att välja mellan!</p>
        </>
      );
    },
    datum: "2024-07-30",
    img: "henry_bronett",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
  },

  {
    id: 15,
    namn: function () {
      return <>Cecilia Gyllenhammar</>;
    },
    info: function () {
      return (
        <>
          <p>
            Med stolthet hälsar vi Cecilia Gyllenhammar välkommen till krogbacken!
            I år utges Cecilias fjärde roman. Efter Sprickan i kristallen, Stäpplöperskan och Sängkammartjuven kommer nu Monologroman om en Vänskap.
          </p>
          <p>
            Monologroman om en Vänskap visar på att tillvarons vindlingar och mysterier är omätbara och överraskande. Det är en roman om att orka ensamhet, om att smycka ensamhet, och om att livet är ett långsamt under.
          </p>
          <p>
            Valerie, en anständig medmänniska i en alltmer osäker värld. Utgångsläget är författarens egna erfarenheter, det långsamma undret livet.
            Berättaren vill öppna ögonen och låta den där vardagen bli till, med fiktiva karaktärer om allt som känns sant för just en människa. Att falla ur sin klass, hamna i konflikt med syskon i skuggan av en berömd far. Att leta efter kärlek mellan 55 och 60, i skräck av att allt ska ta slut. I mötet med en musikerman, angripen av svartsjuka. Att orka ensamhet, att smycka ensamhet. Om utanförskapet som konstnär och mitt i berättelsen, transkvinnan Valerie, som genom samtidens lynniga stormbyar står mer upprätt än de flesta.
          </p>
          <p>
            Cecilia Gyllenhammar är född 1961 och växte upp i Göteborg. År 2004 romandebuterade hon med En spricka i kristallen där hon i nyckelromanform skildrar sin uppväxt, något som väckte starka reaktioner i hennes släkt. Fundament Film producerade en TV-serie i två avsnitt som byggde på romanen. TV-serien sändes av SVT 2007.
          </p>
          <p>
            I sin andra roman Stäpplöperskan (2012) skildrade Cecilia Gyllenhammar äktenskapet med Fredrik von Krusenstjerna. I den tredje romanen, Sängkammartjuven från 2017, fortsätter berättelsen om rollfiguren Suss från En spricka i kristallen.
          </p>
        </>
      );
    },
    datum: "2024-08-06",
    img: "Cecilia_G",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
  },

  {
    id: 16,
    namn: function () {
      return <>Två fotoutställningar</>;
    },
    info: function () {
      return (
        <>
          <p>
            Stefan Fast och Maria del Carmen ställer ut sina fotografier på varsin våning på krogen.
          </p>
          <p>
            Stefan säger om sig själv:<br />
            Jag har mina rötter i naturfotografering men fotar även porträtt och bröllop. I mina bilder arbetar jag mycket med att försöka fånga det rätta ljuset. Jag hoppas att min passion för foto lyser igenom i de bilder jag här visar.
          </p>
          <p>
            Maria säger om sig själv:<br />
            Jag älskar att fotografera och det jag brinner mest för är porträtt. I porträtten vill jag fånga atmosfären, stämningen och ögonblicken som väcker känslor och berättar en historia.

            Många bilder har det blivit genom åren. Att fånga människor i historiska miljöer är ett tema som jag gärna återkommer till. Den här utställningen har jag fokus på kvinnor i 1700-talsmiljöer.
          </p>
          <p>
            UTSTÄLLNINGEN PÅGÅR FRAM TILL 26 JUNI.
          </p>
        </>
      );
    },
    datum: "2024-04-06",
    img: "maria",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
    konst: true,
  },

  {
    id: 17,
    namn: function () {
      return <>Par i bild - två olika uttrycksformer</>;
    },
    info: function () {
      return (
        <>
          <p>
            Helen Biörklund - måleri<br />
            Torbjörn Andersson - foto
          </p>
          <p>
            Helen Biörklund om sig själv:<br />
            Jag började min konstnärliga bana i skolan och valde konstinrktad skolgång, konst och bild sedan 2-åriga kurser på folkhögskola i Vingåker och i Nyköping. Jag gillar att utforska i måleriet och att blanda olika tekniker och material. Jag smälter tenn och har i mitt måleri som går under arbetsnamnet tintabart (har även registrerat det på patent och registreringsverket  för ca: 20 år sedan).
            Jag målar även vanligt klassiskt måleri i akvarell & akryl.
            Har ateljé hemma på gården där jag bor nu och även haft på kulturcentrum i Nyköping i ca 15 år.
          </p>
          <p>
            Torbjörn Andersson om sig själv:<br />
            Jag har fotograferat i snart 60 år.
            I samband med frilansjournalistik sedan 1972 har det blivit mycket fotografering till artiklarna.
            Jag har producerat en rad faktautställningar där fotografiet har haft en stor betydelse.
            Naturfotografering är viktigt för att visa skönheten och magin i naturen och att då samtidigt understryka vikten av att värna om naturen.
            Jag fotograferar något dagligen, ofta en rad foton med mobilen som idag hyser utmärkta kameror.
          </p>
          <p>
            UTSTÄLLNINGEN PÅGÅR FRAM TILL 25 JULI.
          </p>
        </>
      );
    },
    datum: "2024-06-28",
    img: "TorbjornHelen",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
    konst: true,
  },

  {
    id: 18,
    namn: function () {
      return <>Konstutställning: Berit Christoffersson</>;
    },
    info: function () {
      return (
        <>
          <p>
            Jag, Berit Christoffersson, bor i Gamla Oxelösund och har målat akvarell sedan 90-talet. Mentor, inspiratör och påhejare är bildkonstnären Ria Roes Schwartz, som under kurser i Provence och på Öland lagt grunden till mitt måleri. Jag använder mycket färg i flera lager och mina bilder kan upplevas ganska mörka. Jag försöker ändå hitta ett ljus och besjäla bilderna, vilket jag hoppas du ser som betraktare. Jag är ingen ”skärgårdsmålare”, men mina akvareller talar förhoppningsvis till dig ändå.
          </p>
          <p>
            UTSTÄLLNINGEN PÅGÅR FRAM TILL 31 AUGUSTI.
          </p>
        </>
      );
    },
    datum: "2024-07-27",
    img: "berit",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
    konst: true,
  },


  {
    id: 19,
    namn: function () {
      return <>Medeltidens Oxelösund</>;
    },
    info: function () {
      return (
        <>
          <p>
            Vi hälsar historikern Anders Eriksson välkommen till krogbacken!
          </p>
          <p>
            Anders Eriksson, bördig från Sörmland men numera Stockholmare, berättar om det medeltida Oxelösund. Han är utbildad i histora vid Stockholms universitet och arbetar nu med en bok om det medeltida Nyköping.
          </p>
          <p>
            Anders berättar  om Oxelösunds spännande medeltid, där han lyfter fram intressanta platser, annars okända människor och spännande möten, allt genom seklerna innan Gustav Vasa blev kung.
            Kanske finns det nåt att säga om Gustav Vasa också?
          </p>
          <p>
            Anders Eriksson har tidigare givit ut ”Prosten Pihls beskrivning av Nyköping” 2010.
          </p>
        </>
      );
    },
    datum: "2024-07-09",
    img: "anders",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
  },

  {
    id: 20,
    namn: function () {
      return <>Fredman - en brännvinsapostel!</>;
    },
    info: function () {
      return (
        <>
          <p>
            Traditionen fortsätter: Fredman intar krogen i oktober! Äntligen kan ni åter låta er tröstas av hans mäktiga och lugnande stämma! Den tappre brännvinsaposteln låter sig inte tystas av bistra och tunga tider! Tvärtom lever han upp i sin plikt och sitt kall att söka församlingar runt om som vill låta sig inspireras av hans fuktiga leverne! Berusning utlovas, både på scen och under bordet, svammel, predikningar, extravagant gitarrspel, storvulen sång samt upptuktning av församlingen!
          </p><p>
            Konserten ånjuts framför brasan i krogstugan till vår godaste räksoppa med handskalade lyxräkor och halloumismörgås.
          </p>
          <p>
            Kostnad är 350 kr inklusive mat.
          </p>

          <p>
            Så här gör du för att boka och betala konserten.<br />
            Swisha betalningen för samtliga biljetter som bokningen avser.
            Skriv i swishmeddelandet "Fredman" samt din e-postadress.<br />
            Swishnummer: 123 088 11 36 (Thord Lindé Tonkonst).
          </p>

          <p>
            När vi fått din betalning bekräftar vi din bokning med ett mail och då är du garanterad de platser som du beställt.
            Bekräftelse kan ta nån dag men vi svarar alltid.
          </p>
          <p>
            Observera att betalningen måste finnas på vårt konto senast dagen före konsertdatum.
          </p>

        </>
      );
    },
    datum: "2024-10-12",
    img: "fredman",
    pris: 1,
    tid: "18:00",
    kväll: false,
    music: false,
    tonkonst: true,
    slutsålt: false,
    youtube: "https://www.youtube.com/watch?v=t1ggVXHyvNw",
    youtubenamn: "Solen Glimmar",
  }

];

export default artistInfo;