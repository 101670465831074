import Navigation from "./../components/Navigation";
import { Main, Header, Img, Rubrik1, Rubrik2 } from "../styles/AboutStyles";

import krogen from "./../images/krogen_close.jpg";
import rubrik1 from "./../images/rubrik_history1.png";
import rubrik2 from "./../images/rubrik_history2.png";
import HomeButton from "../components/HomeButton";

const History = () => {
    return (
        <>
            <Navigation page={'history'} />
           
            <Main>
                <article> 
                    <Header><h1 alt={"Oxelö Krogs och Gamla Oxelösunds historia"}><Rubrik1 src={rubrik1} width='54%' /><Rubrik2 src={rubrik2} width='84%' width2='46%' /></h1> </Header>
                    <div>
                        <Img src={krogen} />
                        <p>
                            Genom åren har Oxelö Krog varit Lotsplats, lotsbostad, gästgiveri, vandrarhem och hembygdsgård, men är nu återigen Krog och café. Oxelö Krog är också känd som "Bygdegården" i Gamla Oxelösund.
                        </p>
                        <p>
                            Oxelö Krog har anor sedan 1600-talet. Den första krogbyggnaden uppfördes av herren till Stjärnholm. Den förste kända krögaren hette Per, enligt 1686 års mantalsuppgift. Krögaren betalade ett arrande av 100 daler kopparmynt, en tämligen stor summa som visar att omsättningen på krogen var livlig.
                        </p>
                        <p>
                            Gamla Oxelösund är platsen för det ursprungliga Oxelösund. Oxelösund ansågs för en ypperlig hamn och en lotsplats var organiserad här senast 1683. 1697 fanns tre lotsar och tre lotsdrängar i Oxelösund. Anledningen till att Oxelö blev lotsstation var dels att hamnen behövde försvaras så att landstigning av fiender skulle kunna mötas med väpnat motstånd, dels att alla törstiga sjömän behövde en välbelägen krog.
                        </p>
                        <p>
                            På karl IX:s tid anordnades en skans som dock inte verkar ha fungerat väl. 1676 påbörjades en ny men denna verkar aldrig ha tagits i bruk. Konturerna av skansen finns kvar på krogbacken och är utmärkt som fornminne. 
                        </p>
                        <p>
                            I Oxelösund fanns också en begravningsplats för lotsar, fiskare och sjömän för vilka begravningsplatsen i Nyköping tedde sig alltför avlägsen. Idag vittnar endast en ensam gravsten, halvt nedsjunken i marken på tomten där Bagarstugan står, om kyrkogården.

                        </p>
                        <p>
                            År 1719 härjades sörmlandskusten av ryssarna och krogen brändes för krögaren Måns Karlsson. Krogen byggdes tämligen omgående upp igen. Den nuvarande byggnadens ålder är osäker, men någon gång mellan 1730 och 1770 bör den ha uppförts.
                        </p>
                        <p>
                            Fru Emma Utterstedt berättade att hennes mormor, Eva Sundström tjänade som piga i krogen på 1840-talet. Till hennes åliggande hörde, förutom att passa upp i krogsalen, att hjälpa till i lantbruket och framför allt att ro passagerare till Nyköping. Taxan för rodden var 50 öre. 
                        </p>
                        <p>
                            Sommartid fanns ingen farbar väg mellan Nyköping och Oxelösund, annat än för båtar. 1841 anlades dock en väg och krogen började då tjäna som gästgivaregård med skjutsplikt. Den funktionen upphörde på 1880-talet då järnvägen kom till Oxelösund.
                        </p>
                        <p>
                            Gäster fick man genom kusttrafiken, t.ex. genom båten ”Ormen Långe”, den första hjulångaren på denna linje, eller ”Per Lewenius”, den första propellerbåten. Sedan krogen upphörde att vara gästgivaregård blev den privatbostad tills den på 1940-talet skänktes till Södermanlands hembygdsförbund.
                        </p>                        
                    </div>
                    <HomeButton />
                </article>
            </Main>
        </>
    );
}

export default History;