import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";

import clock from "./../images/clock.png";
import kalender from "./../images/kalender.png";
import bestick from "./../images/bestick.png";
import telefon from "./../images/telefon2.png";
import email from "./../images/email6.png";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const IkonerDiv = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  padding: 0.2rem 0.5rem 0 0;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1%;
  z-index: 10;
  background-color: white;

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const Ikon = styled(Link)`
  text-align: center;
  text-decoration: none;

  img {
    width: 40px;
    height: 40px;
  }

  &.sm {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 600px) {
    &.sm {
      display: block;
    }
  }

  @media screen and (min-width: 700px) {
    &.sm,
    &.sp {
      display: none;
    }
    margin-bottom: 10px;
  }
  @media screen and (min-width: 800px) {
    display: block;
    &.sm,
    &.sp {
      display: none;
    }
  }
`;

const AIkon = styled.address`
  text-align: center;
  font-style: normal;
  display: none;

  a {
    text-decoration: none;
  }

  img {
    width: 40px;
    height: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 600px) {
    display: block;
  }

  @media screen and (min-width: 700px) {
    display: none;
  }
  @media screen and (min-width: 800px) {
    display: block;
    display: none;
  }
`;

const Ikontext = styled.div`
  color: black;
  font-size: 10pt;
  line-height: 7px;

  @media screen and (min-width: 800px) {
    /* display: none; */
  }
`;

const TelefonNummer = styled(animated.div)`
  padding: 0.5rem 1rem;
  background-color: beige;
  position: absolute;
  top: 0;
  right: 90px;
  z-index: 10;

  @media screen and (min-width: 700px) {
    display: none;
  }
`;

const Ikoner = () => {
  const [hover, setHover] = useState(0);

  function ShowNumber() {
    const w = window.innerWidth;
    w > 700 ? setHover(-40) : setHover(-40);
  }

  const [styles, api] = useSpring(() => ({ top: 10 }));

  api.start({ top: hover ? hover : 0 });

  return (
    <>
      <Container>
        <TelefonNummer style={styles}>0155 304 22</TelefonNummer>
        <IkonerDiv>
          <Ikon to="/aktuellt" className="sp">
            <img src={kalender} alt="Aktuellt" />
            <Ikontext>Aktuellt</Ikontext>
          </Ikon>
          <Ikon to="/menu">
            <img src={bestick} alt="Meny" />
            <Ikontext>Meny</Ikontext>
          </Ikon>
          <Ikon to="/open">
            <img src={clock} alt="Öppettider" />
            <Ikontext>Öppettider</Ikontext>
          </Ikon>

          <AIkon>
            <a
              href="tel:015530422"
              onMouseOver={ShowNumber}
              onMouseOut={() => setHover(10)}
              className="sm"
            >
              <img src={telefon} alt="Telefon" />
              <Ikontext>Telefon</Ikontext>
            </a>
          </AIkon>

          <Ikon to="/contact" className="sm">
            <img src={email} alt="Kontakt" />
            <Ikontext>Kontakt</Ikontext>
          </Ikon>

          {/* <AIkon href="https://www.facebook.com/oxelokrog" target="_blank" className="sm"><img alt="facebook" src={fb} /><Ikontext>Facebook</Ikontext></AIkon>
                <AIkon href="https://www.instagram.com/oxelokrog/" target="_blank" className="sm"><img alt="instagram" src={insta} /><Ikontext>Instagram</Ikontext></AIkon> */}
        </IkonerDiv>
      </Container>
    </>
  );
};

export default Ikoner;
