import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { useSpring, animated } from 'react-spring';

import Blad from './../images/blad.jpg';

const AnimatedMeny = styled(animated.div)`
  position: absolute;
  left:0;
  right:0;
  top: 0;
  z-index: 99;
  margin-top: -1020px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  background-color: white;
  background-image: url(${Blad});
  background-repeat: no-repeat; 
  background-size: 20%;

  width: ${props => props.type === 'big' ? '100%' : '80%'};
  min-width: 300px;
  max-width: ${props => props.type === 'big' ? '700px' : '500px'};
  text-align: center;
  border-radius: 6px;
  border: double 4px;
  font-size: 1.1rem;
  line-height: 1.4rem;

  h2 {
    font-size: 1.8rem;
    margin-top: 0.6rem;
    margin-bottom: 2rem;
    line-height: 2rem;
  }

  h3 {
    margin-bottom: 0.4rem;
  }

  ul {
    list-style: none;
  }

  @media screen and (min-width: 550px) {
    padding: 2rem;
  }
  @media screen and (min-width: 650px) {
    width: 90%;
    padding: 2.5rem;
  }
  @media screen and (min-width: 700px) {
    margin-top: -1000px;
  }
`;

const Kryss = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  border: 1px solid black;
  border-radius: 3px;
  width:20px;
  height: 20px;
  line-height: 12px;
  font-size: 1.5rem;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`;


const DropWindow = ({ dropdown, setDropdown, children }) => {

  // <Back to Home>
  // Allt detta för att gå bak till Home när animationen är klar
  // så att det funkar att klicka på samma ikon igen
  const navigate = useNavigate();
  const goHome = useCallback(() => navigate('/', { replace: true }), [navigate]);

  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (finished) {
      goHome();
    }
  }, [finished, goHome]);

  function getGoHome() {
    if (!dropdown) {
      setFinished(true);
    }
  }
  // </Back to Home>

  const clickEvent = () => {
    setDropdown(false);
  }

  const { opacity, top } = useSpring({
    from: {
      opacity: 0,
      top: 0
    },
    to: {
      opacity: dropdown ? 1 : 0,
      top: dropdown ? 1090 : 0
    },
    config: {
      mass: 5, tension: 350, friction: 40,
    }, onRest: {
      opacity: getGoHome,
    }
  })

     /* <CLEANUP> Runs on unmount and works! */
     const [, setState] = useState({});
     useEffect(() => {
         myFunction();
         return () => {
             setState({}); // This worked for me
         };
     }, []);
     const myFunction = () => {
         setState({
             name: 'John',
             surname: 'Doe',
         })
     }
     /* </CLEANUP> */

  return (
    <AnimatedMeny type={dropdown === 'time' ? 'small' : 'big'}
      style={{ position: "absolute", opacity, top }}>
      <Kryss onClick={clickEvent}>x</Kryss>

      { children }

    </AnimatedMeny>
  )
};

export default DropWindow;