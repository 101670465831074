import React, { useState } from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

import logo_img from "./../images/logo_img.png";
import logo_text from "./../images/logo_text.png";
import telefon from "./../images/telefon.png";
import email from "./../images/email.png";
import NavToggle from "./dep/nav/NavToggle";

import { useSpring, animated } from "@react-spring/web";

const Container = styled.div`
  position: relative;
  max-width: 1100px;
`;
const NavWrap = styled.section`
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 101;
  width: 100%;
  max-width: 1100px;
  background: white;
  /* border-bottom: 1px solid #aaa; */

  letter-spacing: 0.2pt;
  color: black;

  @media screen and (min-width: 410px) {
    grid-template-columns: 1fr 120px 50px;
  }

  @media screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: 40px minmax(auto, 1100px);
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    height: auto;
    padding-right: 1.5rem;
  }
`;

const Nav = styled.nav`
  position: absolute;
  z-index: -1;
  top: 3.5rem;
  left: -110%;
  width: 100%;
  transition: 0.22s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    background-size: cover;
  }

  ul {
    list-style-type: none;
  }

  ul li a {
    font-size: 1rem;
    font-weight: 600;
    color: black;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    padding: 1.5rem 0.7rem 0.5rem 2.3rem;
    border-bottom: solid 1px rgba(0, 0, 0, 0.5);
    transition: 0.14s ease-in-out;

    @media screen and (min-width: 700px) {
      padding: 1.5rem 0.7rem 0.8rem 0.5rem;
    }
  }
  ul li a:hover {
    color: #36bae6;
    background: #e0e5eb;
  }

  @media screen and (min-width: 700px) {
    height: 40px;
    position: initial;

    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
    }

    ul li a {
      border-bottom: none;
      font-size: 0.9rem;
    }

    ul li a:hover {
      background: transparent;
    }

    &::after,
    nav ul li:last-of-type::before {
      display: none;
      padding: initial;
    }

    .KONTAKT {
      display: none;
    }
  }
`;

const Adress = styled.address`
  text-align: right;
  padding: 0 0.7rem;
  font-style: initial;
  display: none;
  align-self: end;
  margin-bottom: 8px;

  a {
    display: none;
  }

  /* grid-column-start: 2; */

  @media screen and (min-width: 410px) {
    display: block;
    .postadress {
      display: none;
    }
  }
  @media screen and (min-width: 700px) {
    grid-row: 2 / 3;

    a {
      display: inline-block;
    }
    .postadress {
      display: inline-block;
      margin-right: 0.9rem;
    }
  }

  a {
    text-decoration: none;
    color: black;
  }
`;

const Telefon = styled.img`
  display: inline-block;
  width: 25px;
  vertical-align: middle;
  margin-left: 0;
  padding-bottom: 4px;
`;

const Email = styled(Telefon)`
  width: 32px;
  margin-left: 0.9rem;
`;

const TelefonNummer = styled(animated.div)`
  padding: 0.5rem 1rem;
  background-color: beige;
  position: absolute;
  top: 30px;
  right: 65px;
  z-index: 10;

  @media screen and (min-width: 700px) {
    top: 50px;
    right: 35px;
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0.1rem 0 0.1rem 0.5rem;
  transition: 0.5s ease-in-out;

  .logo_img {
    width: 110px;
  }

  .logo_text {
    width: 110px;
    transition: 0.5s ease-in-out;
  }

  @media screen and (min-width: 450px) {
    margin-left: 2rem;
  }

  @media screen and (min-width: 700px) {
    grid-row: 1 / 3;
    margin: 0.4rem 0 0.3rem 2rem;

    .logo_img {
      width: 130px;
    }

    .logo_text {
      width: 125px;
    }
  }
`;

const Navigation = ({ page }) => {
  const LS = require("./dep/nav/navlinks.js").default;
  const links = LS[page];

  const hideMenu = () => {
    return () => {
      const p = document.getElementById("navator");
      p.checked = false;
    };
  };

  // Flyttat till Ikoner
  const [hover, setHover] = useState(0);

  function ShowNumber() {
    const w = window.innerWidth;
    w > 700 ? setHover(90) : setHover(70);
  }

  const [styles, api] = useSpring(() => ({ top: 30 }));

  api.start({ top: hover ? hover : 30 });

  return (
    <Container>
      <NavWrap>
        <Logo className="logo">
          <img id="logo_img" src={logo_img} alt="logo" className="logo_img" />
          <img
            id="logo_text"
            src={logo_text}
            alt="Oxelö Krog"
            className="logo_text"
          />
        </Logo>

        <Adress>
          <span className="postadress">Gamla Oxelösundsvägen 28 </span>

          <a
            href="tel:015530422"
            onMouseOver={ShowNumber}
            onMouseOut={() => setHover(0)}
          >
            <Telefon src={telefon} />
          </a>

          <Link to={page === "home" ? "contact" : "./../../contact"}>
            <Email src={email} />
          </Link>
        </Adress>

        <NavToggle />

        <Nav>
          <ul>
            {links.map((obj) => (
              <li key={obj.page} className={obj.name}>
                <Link to={"../" + obj.page} onClick={hideMenu()}>
                  {obj.name}
                </Link>
              </li>
            ))}
          </ul>
        </Nav>
      </NavWrap>
      <TelefonNummer style={styles}>0155 304 22</TelefonNummer>
    </Container>
  );
};

export default Navigation;
