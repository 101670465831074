import { Outlet } from "react-router-dom";
import Footer from "./../components/Footer";

const About = () => {
    return (
        <>
            
            <Outlet />
            <Footer />
        </>
    );
}

export default About;