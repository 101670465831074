import styled from "styled-components";

import facebook from "./../images/facebook_color.png";
import instagram from "./../images/instagram_color.png";

const FooterStyle = styled.footer`
    display: flex;
    justify-content: center;
    bottom: -1px;
    background-color: #fff;
    font-size: 0.9rem;
    color: black;
    padding: 7px;
    text-align: center;
    z-index: 100;
    border-top: 0.5px solid #444;

    img {
        width: 33px;
        height: 33px;
    }

    @media screen and (min-width: 700px) {
        /* display: none; */
    }
`;

const Address = styled.address`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    line-height: 1.2rem;
    margin: 0 1.5rem;

    .between {
        display: none;
        padding: 0 0.5rem;
    }

    @media screen and (min-width: 500px) {
        flex-direction: row;
        .between {
            display: inline-block;
        }
    }
`

const Footer = () => {
    return (
        <FooterStyle>    
            <a href="https://www.facebook.com/oxelokrog"><img src={facebook} alt="facebook icon" /></a>
            <Address><div>Gamla Oxelösundsvägen 28 </div> <div className="between">|</div>  <div> Tel: 0155 304 22</div> </Address>     
            <a href="https://www.instagram.com/oxelokrog"><img src={instagram} alt="instagram icon" /></a>
        </FooterStyle>
    );
}

export default Footer;