const links = {
  home: [
    { page: "aktuellt", name: "AKTUELLT" },
    { page: "about/welcome", name: "OM OSS" },
    { page: "about/visit", name: "HITTA HIT" },
    { page: "about/history", name: "HISTORIA" },
    { page: "#gallery", name: "BILDER" },
    { page: "contact", name: "KONTAKT" },
  ],
  stage: [
    { page: "../about/visit", name: "HITTA HIT" },
    { page: "/sponsorer", name: "VÅRA SPONSORER" },
    { page: "../contact", name: "KONTAKT" },
    { page: "../", name: "HEM" },
  ],
  welcome: [
    { page: "visit", name: "HITTA HIT" },
    { page: "history", name: "HISTORIA" },
    { page: "../#gallery", name: "BILDER" },
    { page: "../contact", name: "KONTAKT" },
    { page: "../", name: "HEM" },
  ],
  formular: [
    
  ],
  visit: [
    { page: "welcome", name: "OM OSS" },
    { page: "history", name: "HISTORIA" },
    { page: "../#gallery", name: "BILDER" },
    { page: "../contact", name: "KONTAKT" },
    { page: "../", name: "HEM" },
  ],
  history: [
    { page: "welcome", name: "OM OSS" },
    { page: "visit", name: "HITTA HIT" },
    { page: "../#gallery", name: "BILDER" },
    { page: "../contact", name: "KONTAKT" },
    { page: "../", name: "HEM" },
  ],
  bilder: [
    { page: "aktuellt", name: "AKTUELLT" },
    { page: "about/welcome", name: "OM OSS" },
    { page: "about/visit", name: "HITTA HIT" },
    { page: "../contact", name: "KONTAKT" },
    { page: "../", name: "HEM" },
  ],
  menu: [
    { page: "aktuellt", name: "AKTUELLT" },
    { page: "about/welcome", name: "OM OSS" },
    { page: "about/visit", name: "HITTA HIT" },
    { page: "about/history", name: "HISTORIA" },
    { page: "../contact", name: "KONTAKT" },
    { page: "#gallery", name: "BILDER" },
  ],
  aktuellt: [
    { page: "about/welcome", name: "OM OSS" },
    { page: "about/visit", name: "HITTA HIT" },
    { page: "about/history", name: "HISTORIA" },
    { page: "#gallery", name: "BILDER" },
  ],
  open: [
    { page: "aktuellt", name: "AKTUELLT" },
    { page: "about/welcome", name: "OM OSS" },
    { page: "about/visit", name: "HITTA HIT" },
    { page: "about/history", name: "HISTORIA" },
    { page: "#gallery", name: "BILDER" },
  ],
  contact: [
    { page: "aktuellt", name: "AKTUELLT" },
    { page: "about/welcome", name: "OM OSS" },
    { page: "about/visit", name: "HITTA HIT" },
    { page: "about/history", name: "HISTORIA" },
    { page: "#gallery", name: "BILDER" },
  ],
  sponsorer: [
    { page: "about/visit", name: "HITTA HIT" },
    { page: "../contact", name: "KONTAKT" },
    { page: "/", name: "HEM" },
  ],
};

export default links;
