import React, { useEffect, useState } from "react";

import Grid from "../components/Grid";
import artistInfo from "./../lib/artistInfo";

export default function Stage({ cal, page }) {
  const [, setKalender] = useState([]);

  async function getEvents() {
    /* Här kan även lokal json-fil hämtas - men med full url.  */
    /*
        const response = await fetch('https://www.oxelokrog.se/api/evenemang/read.php');
        const data = await response.json();
       */
    setKalender(artistInfo);
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <Grid kalender={artistInfo} page={page} />
    </>
  );
}
