import styled from "styled-components";
import ScrollBackButton from "./../components/ScrollBackButton";

import FancyHeading from "./../components/FancyHeading";

import gal1_l from "./../images/galleri/krogen_close_l.jpg";
import gal2_w from "./../images/galleri/krogen_w.jpg";
import gal3_p from "./../images/galleri/pasktallrik_p.jpg";
import gal4_l from "./../images/galleri/parasoller_l.jpg";
import gal5_w from "./../images/galleri/night_w.jpg";
import gal6_l from "./../images/galleri/krogstugan_l.jpg";
import gal7_p from "./../images/galleri/vinter_p.jpg";
import gal8_l from "./../images/galleri/kanelbullar_l.jpg";
import gal9_w from "./../images/galleri/evenemang_w.jpg";
import gal10_w from "./../images/galleri/krogen2_w.jpg";
import gal11_p from "./../images/galleri/ellen_p.jpg";
import gal12_l from "./../images/galleri/jul_l.jpg";
import gal13_l from "./../images/galleri/cars_l.jpg";
import gal14_p from "./../images/galleri/rakmacka_p.jpg";
import gal15_l from "./../images/galleri/1700_l.jpg";
import gal16_w from "./../images/galleri/night2_w.jpg";
import gal17_p from "./../images/galleri/krogen_p.jpg";
import gal18_w from "./../images/galleri/kakbord_w.jpg";
import gal19_l from "./../images/galleri/smorrebrod_lax_l.jpg";
import gal20_l from "./../images/galleri/meatballs_l.jpg";
import gal21_p from "./../images/galleri/glassrea_p.jpg";
import gal22_l from "./../images/galleri/krogstugan_l.jpg";
import gal23_l from "./../images/galleri/vinter2_l.jpg";
import gal24_p from "./../images/galleri/eld_p.jpg";
import gal25_l from "./../images/galleri/flygbild_l.jpg";
import gal26_l from "./../images/galleri/maria_l.jpg";
import gal27_l from "./../images/galleri/flygbild2_l.jpg";

const Gallery = styled.article`
    max-width: 1200px;
    margin: 0.5rem 1rem 1rem 0.5rem;
`;

const GalleryWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: .5em;
    grid-auto-flow: row dense;

    img.w {
        grid-column-end: span 2;
    }

    img.p {
        grid-row-end: span 2;
    }

    img.extra {

        @media screen and (min-width: 783px) {
            display: none;
        }
        @media screen and (min-width: 1040px) {
            display: block;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;




/* ############ 10c. Feature Query ############ */
/* SAFARI 11+ */
//   @media not all and (min-resolution:.001dpcm) {
//     @supports (-webkit-appearance:none) and (stroke-color:transparent) {
//       .galleryWrap  {
//         align-items: stretch;
//         }
//         .galleryWrap img {
//           height: inherit;
//         }
//       }
//     }


const Images = () => {

    return (
        <Gallery id="gallery">
            <FancyHeading cont="Bilder från Oxelö Krog " more=" i Gamla Oxelösund" />
            <GalleryWrap>
                <img alt="Oxelö Krog" src={gal1_l} />
                <img alt="Påsktallrik" className="p" src={gal3_p} />
                <img alt="Vy med parasoller" src={gal4_l} />
                <img alt="Krogbacken by night" className="w" src={gal5_w} />
                <img alt="Krogstugan" src={gal6_l} />
                <img alt="Vintervy" className="p" src={gal7_p} />
                <img alt="Kanelbullar" src={gal8_l} />
                <img alt="Konsert på Krogbacken" className="w" src={gal9_w} />
                <img alt="Servering i glasskiosken" className="w" src={gal10_w} />
                <img alt="Julstämning" className="p" src={gal11_p} />
                <img alt="Veteranbilar" src={gal12_l} />
                <img alt="Räksmörgås" src={gal13_l} />
                <img alt="1700-talsmänniskor" className="p" src={gal14_p} />
                <img alt="Oxelö by night" src={gal15_l} />
                <img alt="kakbord" className="w" src={gal16_w} />
                <img alt="Krogen" className="p" src={gal17_p} />

                <img alt="Smörrebröd" src={gal19_l} />
                <img alt="Kakbord" className="w" src={gal18_w} />
                <img alt="Köttbullar" src={gal20_l} />
                <img alt="Glassrea" className="p" src={gal21_p} />
                <img alt="Krogstugan" src={gal22_l} />
                <img alt="Oxelö Krog" className="w" src={gal2_w} />
                <img alt="Vintervy" src={gal23_l} />
                <img alt="Eld i öppna spisen" className="p" src={gal24_p} />
                <img alt="Flygbild över Gamla Oxelösund" src={gal25_l} />
                <img alt="Maria" src={gal26_l} />
                <img alt="Flygbild över Gamla Oxelösund" className="extra" src={gal27_l} />
            </GalleryWrap>
            <p>&nbsp;</p>
              <ScrollBackButton />  
            <p>&nbsp;</p>
            
        </Gallery>
    )
}

export default Images;
