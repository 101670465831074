import React, { useState, useEffect } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

import Blad from './../images/blad.jpg';

const Form = styled.form`
  display: block;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 6rem;
  background-color: white;
  background-image: url(${Blad});
  background-repeat: no-repeat; 
  background-size: 20%;
  border-radius: 6px;
  border: double 4px;
  line-height: 1.4rem;

  h1 {
    text-align: center;
    font-size: 2.5rem;
  }

  input {
    width: 280px;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }

  textarea {
    width: 100%;
    height: 130px;
  }

  button {
    padding: 1px 8px;
    border-radius: 12px;
    box-shadow: -1px 2px 2px #666;
    :hover {
      cursor: pointer;
    }
  }
`;

const Ingress = styled.p`
  margin-top: 1rem;
  display: block;
  text-align: center;
`;

const Fieldset = styled.fieldset`
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  input {
    width: auto;
  }
  padding: 1rem;
`;

const Infobox = styled.div`
  margin-left: 1rem;
`;

const Tack = styled.p`
  text-align: center;
`;


const FormContent = () => {
    const [submitted, setSubmitted] = useState(false);
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [annat, setAnnat] = useState("");

    const handleSubmit = () => {

        const email = 'hej@oxelokrog.se';

        let checkboxes = document.getElementsByName("information");
        let info = "";
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked)
                info += checkboxes[i].value + " ";
        }

        if (info) { /*  && email && message */
            const serviceId = "service_krogen";
            const templateId = "template_rknvn3g";
            const userId = "YDlI-kZs_WXHc0WPE";
            const templateParams = {
                name,
                email,
                message,
                info
            };

            emailjs
                .send(serviceId, templateId, templateParams, userId)
                .then((response) => console.log(response))
                .then((error) => console.log(error));

            setName("");
            setMessage("");
            setAnnat("");
            info = "";
            setSubmitted(true);
        } else {
            alert("Var snäll att fylla i alla fält.");
        }
    };

    if (submitted) {
        return (
            <>
                <Tack>
                    <p>Tack för att du hjälper oss att bli bättre!</p>
                    <p>Bästa hälsningar från <br />föreningen Kultur på Krogbacken</p>
                </Tack>

            </>
        );
    }

    return (
        <Form onSubmit={handleSubmit} method="post" target="_blank">
            <h1>FRÅGOR TILL KONSERTBESÖKAREN</h1>
            <Ingress>
                Vi vill gärna veta var du hittade information om våra konserter.<br />
                Du får gärna även skriva hur du upplevde konserten, tips för framtiden och om det är
                någon särskild artist du skulle vilja se på Krogbacken.
            </Ingress>

            <Fieldset>
                <legend>Var fann du information om våra konserter?<br />Du kan bocka i flera alternativ.</legend>
                <Infobox>
                    <input type="checkbox" id="sn" name="information" value="sn" />
                    <label htmlFor="sn">Sörmlands Nyheters sommarbilaga</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="expresser" name="information" value="expressen" />
                    <label htmlFor="expressen">Expressens sommarbilaga</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="sormlandsleden" name="information" value="sormlandsleden" />
                    <label htmlFor="sormlandsleden">Sörmlandsleden, turistmaterial</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="sörmlandskartan" name="information" value="sörmlandskartan" />
                    <label htmlFor="sörmlandskartan">Sörmlandskartan, annons</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="utflyktsvägen" name="information" value="utflyktsvägen" />
                    <label htmlFor="utflyktsvägen">Utflyktsvägen, annons</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="vagskylten" name="information" value="vagskylten" />
                    <label htmlFor="vagskylten">Stora digitala tavlan vid vägen</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="orkesterjournalen" name="information" value="orkesterjournalen" />
                    <label htmlFor="orkesterjournalen">Orkesterjournalen (tidningen Jazz)</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="fb" name="information" value="fb" />
                    <label htmlFor="fb">Facebook</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="insta" name="information" value="insta" />
                    <label htmlFor="insta">Instagram</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="affisch" name="information" value="affisch" />
                    <label htmlFor="affisch">Affisch</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="flygbl" name="information" value="flygbl" />
                    <label htmlFor="flygbl">Flygblad</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="visitOx" name="information" value="visitOx" />
                    <label htmlFor="visitOx">Visit Oxelösund</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="NykGuiden" name="information" value="NykGuiden" />
                    <label htmlFor="NykGuiden">Nyköpingsguiden</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="VisitSorml" name="information" value="VisitSorml" />
                    <label htmlFor="VisitSorml">Visit Sörmland</label>
                </Infobox>
                <Infobox>
                    <input type="checkbox" id="hörsägen" name="information" value="hörsägen" />
                    <label htmlFor="hörsägen">Hörsägen</label>
                </Infobox>
                <Infobox>
                    Annat: <input
                        name="annat"
                        type="text"
                        placeholder="skriv..."
                        value={annat}
                        onChange={(e) => setAnnat(e.target.value)}
                    />
                </Infobox>
            </Fieldset>
            <div><br />
                <div>Synpunkter och tips till oss</div>

                <textarea
                    name="message"
                    placeholder="Skriv här!"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                /*required*/
                />{" "}
            </div>

            <p>
                Namn (frivilligt): <input
                    name="name"
                    type="text"
                    placeholder="namn"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </p>
            {/* 
            <div>
                <input
                    name="email"
                    type="email"
                    placeholder="e-postadress"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required 
                />
            </div>
            */}

            <button type="submit">SKICKA</button>
        </Form>
    );
};

function Formular() {

    return (
        <>
            <div>
                <FormContent />
            </div>
        </>
    );
}

export default Formular;
