import React, { useState, useEffect } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

import DropWindow from "./../components/DropWindow";

const Form = styled.form`
  display: inline-block;
  margin: auto;
  padding: 1.5rem;
  background-color: rgba(0, 100, 0, 0.3);
  input {
    width: 280px;
  }

  textarea {
    width: 100%;
    height: 130px;
  }

  div {
    text-align: center;
    margin-bottom: 1rem;
  }

  button {
    padding: 1px 8px;
    border-radius: 12px;
    box-shadow: -1px 2px 2px #666;
    :hover {
      cursor: pointer;
    }
  }
`;

const Address = styled.address`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  text-align: left;
`;

const FormContent = () => {
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (name && email && message) {
      const serviceId = "service_krogen";
      const templateId = "template_rknvn3g";
      const userId = "YDlI-kZs_WXHc0WPE";
      const templateParams = {
        name,
        email,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => console.log(response))
        .then((error) => console.log(error));

      setName("");
      setEmail("");
      setMessage("");
      setSubmitted(true);
    } else {
      alert("Var snäll att fylla i alla fält.");
    }
  };

  if (submitted) {
    return (
      <>
        <p>Tack för att du kontaktat oss!</p>
        <p>Vi besvarar din förfrågan inom kort!</p>
        <p>Thord och Maria</p>
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit} method="post" target="_blank">
      <div>
        <input
          name="name"
          type="text"
          placeholder="namn"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <input
          name="email"
          type="email"
          placeholder="e-postadress"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <textarea
          name="message"
          placeholder="meddelande"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />{" "}
      </div>
      <button type="submit">SKICKA</button>
    </Form>
  );
};

function Contact() {
  const [dropdown, setDropdown] = useState("");

  function getContact() {
    setDropdown("contact");
  }

  useEffect(() => {
    getContact();
  }, []);

  return (
    <>
      <DropWindow dropdown={dropdown} setDropdown={setDropdown}>
        <h1>KONTAKTA OSS!</h1>
        <FormContent />
        <Address>
          <div>
            <div>Oxelö Krog</div>
            <div>Gamla Oxelösundsvägen 28</div>
            <div>613 30 Oxelösund</div>
            <div>Tel: 0155 304 22</div>
          </div>
        </Address>
      </DropWindow>
    </>
  );
}

export default Contact;
