import { useState, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";

import pratbubbla from "./../images/pratbubbla.png";

const BubbelStil = styled(animated.div)`
  position: absolute;
  /* top: ${(props) => props.top}; */
  left: 98px;
  background: url(${pratbubbla});
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 100;
  color: black;
  line-height: ${(props) => props.lineheight};
  /* width: ${(props) => props.width};
    height: ${(props) => props.height}; */
  padding: 1rem 0.6rem;
  user-select: none;
  margin-bottom: ${(props) => -props.marginBottom + "px"};

  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => props.align};

  /* @media screen and (min-width: 700px) {
        left: 125px;
        top: calc(${(props) => props.top} + 8px);
    } */

  &:hover {
    cursor: pointer;
  }
`;

let hello = "";

const Pratbubbla = ({ page }) => {
  hello =
    window.innerWidth < 700
      ? "Kom och njut av hemlagad mat och underbar musik i en idyllisk skärgårdsmiljö med utsikt över öar och båtliv!"
      : "Du kollar väl in vårt fina konsertprogram! Kom och njut av en sommarkväll med bra musik, god mat och en underbar utsikt!";

  const [bubble, setBubble] = useState(0);
  const [bubbeltext, setBubbeltext] = useState("...");

  // Hindrar sidan från att laddas om och sätter den i startläge på ny sida
  useEffect(
    (e) => {
      setBubble(0);
    },
    [page]
  );

  function bubbleVariant(v1, v2, v3) {
    return bubble === 1 ? v1 : bubble === 2 ? v2 : v3;
  }

  function doText() {
    setBubbeltext("");
    setTimeout(setBubbeltext, 1000, bubble === 1 ? "..." : hello);
  }

  const [{ width, height, top }, api] = useSpring(() => ({
    width: "70px",
    height: "60px",
    top: "-10px",
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const wb = ["70px", "190px", "45px"];
  const hb = ["60px", "160px", "35px"];
  const tb = ["-10px", "-98px", "20px"];

  api.start({
    width: wb[bubble],
    height: hb[bubble],
    top: tb[bubble],
  });

  return (
    <BubbelStil
      style={{ width, height, top }}
      onClick={(e) => {
        e.stopPropagation();
        setBubble(bubble < 2 ? bubble + 1 : 1);
        doText();
      }}
      fontSize={bubbleVariant("0.9rem", "1rem", "20pt")}
      lineheight={bubbleVariant("12pt", "2pt", "12pt")}
      align={bubble === 1 ? "center" : "center"}
    >
      {bubbeltext}
    </BubbelStil>
  );
};

export default Pratbubbla;
