import styled from "styled-components";

function Epost() {
    var part1 = "hej";
    var part2 = Math.pow(2, 6);
    var part3 = String.fromCharCode(part2);
    var part4 = "oxelokrog.se";
    var part5 = part1 + String.fromCharCode(part2) + part4;

    return (
        <span><a href={"mai" + "lto" + ":" + part5}>{part1 + part3 + part4}</a></span>
    );
}

export default Epost;