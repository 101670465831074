function importAll(r) {
	let images = {};
	r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
	return images
}

const images = importAll(require.context('./../images/artister/2024', false, /\.(png|jpe?g|svg)$/));

const getImages = images;

export default getImages;