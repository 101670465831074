import styled from "styled-components";

import dots from "./../images/dots.png";

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 1.5rem 0 0.4rem 0;
  /* background-image: url(${dots}); */
  background-color: rgba(180, 182, 114, 0.4);
  background-position: left center;
  background-size: 14px;
  background-repeat: repeat-x;
  height: 40px;
  border-radius: 10px 10px 0 0;

  @media screen and (min-width: 720px) {
  }
`;
const Heading = styled.div`
  display: flex;
  justify-content: space-between;

  height: 40px;
  border-radius: 10px;

  img {
    width: 25px;
    height: 40px;
  }
`;

const H1 = styled.h1`
  margin: 4px 5px 0 5px;
  color: #000;
  font-size: 2.5rem;

  .more {
    display: none;
  }

  @media screen and (min-width: 780px) {
    .more {
      display: inline-block;
    }
  }
`;

const FancyHeading = ({ cont, more }) => {
  return (
    <Header>
      <Heading>
        {/* <img src={parantes_left} />  */}
        <H1>
          {cont} <span className="more">{more}</span>
        </H1>
        {/* <img src={parantes_right} /> */}
      </Heading>
    </Header>
  );
};

export default FancyHeading;
